@import "normalize.css";

$color: (
	"accent": #8954ff,
	"mark": #ffffff,
	"bg": #1b1b1c,
);

@font-face {
	font-family: "DM Sans";
	font-weight: 700;
	font-display: swap;
	src: url("../../fonts/dm-sans/DMSans-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "DM Sans";
	font-weight: 500;
	font-display: swap;
	src: url("../../fonts/dm-sans/DMSans-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "DM Sans";
	font-weight: 400;
	font-display: swap;
	src: url("../../fonts/dm-sans/DMSans-Regular.ttf") format("truetype");
}

html {
	font-size: 16px;

	body {
		font-family: "DM Sans", sans-serif;
		font-weight: 500;
		color: map-get($color, "mark");
		background-color: map-get($color, "bg");
	}

	p {
		line-height: 1.375em;
	}

	mark {
		color: map-get($color, "mark");
		background: linear-gradient(
			to bottom,
			transparent 65%,
			rgba(map-get($color, "accent"), 0.5) 50%
		);
	}
}

.bgs {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	opacity: 0.125;

	video {
		position: fixed;
		right: 0;
		bottom: 0;
		min-width: 100%;
		min-height: 100%;
		transform: translateX(calc((100% - 100vw) / 2));
	}
}

header.primary {
	color: map-get($color, "mark");
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 4rem;
	font-size: 1rem;

	@media (max-width: 968px) {
		align-items: start;
	}

	.header-logo {
		color: map-get($color, "mark");
		display: block;
		height: 32px;
		width: auto;
	}

	nav {
		font-size: 1.25rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 2rem;

		@media (max-width: 968px) {
			font-size: 0.875rem;
			flex-direction: column-reverse;
			align-items: end;
		}

		a {
			transition: 0.125s ease opacity;

			@media (hover: hover) {
				&:hover {
					opacity: 0.75;
				}
			}
		}

		a:not(.button-style) {
			color: map-get($color, "mark");
			text-decoration: none;
		}
	}
}

button,
.button-style {
	appearance: none;
	display: block;
	text-decoration: none;
	font-family: "Space Grotesk", sans-serif;
	font-size: 1.5rem;
	font-weight: 700;
	background-color: map-get($color, "accent");
	color: map-get($color, "mark");
	border: none;
	padding: 0.25em 1em;
	border-radius: 0.25em;
	outline: 0.06725em solid map-get($color, "accent");
	outline-offset: -1px;
	box-shadow: 0 0.5em 1.5em rgba(map-get($color, "accent"), 0.5);
	transition: 0.25s ease background-color, 0.25s ease box-shadow,
		0.25s ease outline;
	cursor: pointer;
	white-space: nowrap;

	@media (hover: hover) {
		&:hover {
			outline: 0.25em solid darken(map-get($color, "accent"), 2.5%);
			background-color: darken(map-get($color, "accent"), 2.5%);
			box-shadow: 0 0.5em 1.5em rgba(map-get($color, "accent"), 0.75);
		}
	}
}

#page-content {
	position: relative;
	z-index: 2;
	width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	gap: 4rem;
	padding: 4rem;
	overflow: hidden;
	box-sizing: border-box;
	max-width: 1280px;
	margin: 0 auto;

	@media (max-width: 768px) {
		padding: 2rem;
	}

	&::before {
		content: "";
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-size: cover;
		background-attachment: fixed;
		background-blend-mode: multiply;
	}

	&.home {
		overflow: hidden;

		.splash {
			z-index: 2;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			gap: 1.5rem;
			padding: 0;
			border-radius: 1rem;
			flex-grow: 1;

			p {
				margin: 0;

				&.big {
					line-height: 1.25em;
					font-size: 4ch;
					font-weight: 400;

					@media (max-width: 768px) {
						font-size: 1.5rem;
					}
				}

				&:not(.big) {
					line-height: 1.5em;
				}
			}

			> * {
				margin: 0;
			}

			img.joe-avatar {
				display: block;
				width: 8rem;
				border-radius: 100%;
				overflow: hidden;
				outline: 1rem solid white;
				align-self: center;
			}
		}
	}
}

.clients-grid {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 2rem;

	a {
		img {
			display: block;
			height: 1.5rem;
		}

		@media (hover: hover) {
			&:not(:hover) {
				filter: grayscale(100%) invert(1);
			}
		}
	}
}

#page-content {
	.animate-me {
		display: inline;
		position: relative;
		transition: 2s ease opacity, 0.5s ease transform;

		&:not(.animate-in) {
			opacity: 0;
			transform: translate(0, -2rem);
			pointer-events: none;
		}

		&.animate-in {
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}
